import React, { Component, useState, useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import API from '../services/API';
import { CURRENT_USER_URL } from '../constants';
import { useDispatch } from 'react-redux';
import { loginActions } from '../store/login-slice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Icon from 'feather-icons-react';
const Navbar = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [username, setUsername] = useState('')
  useEffect(() => {
    getUsersInfo();
  }, [])

  const getUsersInfo = async () => {
    try {
      const response = await API.get(CURRENT_USER_URL);

      if (response.status === 200) {
        setUsername(response.data.firstName)
        dispatch(loginActions.setUser(response.data))

      } else {
        history.push('/login')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  const getUserName = () => {
    const name = username.split(' ');
    console.log(name)
    if (name[1]) {
      const f = name[0].slice(0, 1)
      const l = name[1].slice(0, 1)
      return f + '' + l

    } else {
      return name[0].slice(0, 2)
    }

  }
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row navbar-boxshadow bg-white" >
      {/* <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="icon-menu"></span>
        </button>
        <Link className="navbar-brand brand-logo" to="/"><img src={require("../../assets/images/logo.png")} className="me-2" alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
      </div> */}
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
            <h2 className="welcome-text d-flex align-items-center"><img src={require("../../assets/images/logo.png")} className="me-3" alt="logo" style={{ width: 80 }} /><span className="text-black fw-bold ps-3 headtitle"> Environment, Health and Safety</span></h2>

          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          <li className='nav-item'>
            <div class="search">
              <span class="fa fa-search"></span>
              <input placeholder="Search " className='form-control' />
            </div>
          </li>
          <li className="nav-item">
            <Icon.Settings className="menu-icon" />
          </li>

          <li className="nav-item">

            {/* <Link className="nav-link" to="/logout"> */}
            {/* <i className="mdi mdi-power logout-icon" style={{fontSize:30}}></i> */}
            <Icon.Bell className="menu-icon" />

            {/* </Link> */}

          </li>
          <li className="nav-item nav-profile">

            <Dropdown>
              <Dropdown.Toggle className="nav-link">
                <div className='bg-secondary name-icon' >{getUserName()}</div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown test" >

                <Dropdown.Item className="dropdown-item preview-item" onClick={() => history.push('/logout')}>
                  <div className="d-flex align-items-center">
                    <i className="ti-power-off text-primary"></i>
                    <span className="ps-2">Logout</span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </li>



        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );
}

const DefaultDatepicker = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker selected={startDate} onChange={date => setStartDate(date)} className="form-control" style={{ 'z-index': 3 }} />
  );
};
export default Navbar;
